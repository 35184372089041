/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActionBetweenUnits {
  CAN_MOVE_TO = "CAN_MOVE_TO",
  CAN_PASTE_TO = "CAN_PASTE_TO",
}

export enum ActionOnUnit {
  CAN_ARCHIVE = "CAN_ARCHIVE",
  CAN_CONVERT_TO_ACCOUNTING_MEASUREMENT = "CAN_CONVERT_TO_ACCOUNTING_MEASUREMENT",
  CAN_COPY = "CAN_COPY",
  CAN_CREATE = "CAN_CREATE",
  CAN_CUT = "CAN_CUT",
  CAN_DELETE = "CAN_DELETE",
  CAN_EDIT = "CAN_EDIT",
  CAN_EXPORT_DATA = "CAN_EXPORT_DATA",
  CAN_EXPORT_STRUCTURE = "CAN_EXPORT_STRUCTURE",
  CAN_IMPORT_DATA = "CAN_IMPORT_DATA",
  CAN_IMPORT_STRUCTURE = "CAN_IMPORT_STRUCTURE",
  CAN_INPUT_DATA_MANUALLY = "CAN_INPUT_DATA_MANUALLY",
  CAN_MANAGE_SUBSCRIPTIONS = "CAN_MANAGE_SUBSCRIPTIONS",
  CAN_MANAGE_USERS = "CAN_MANAGE_USERS",
  CAN_UNARCHIVE = "CAN_UNARCHIVE",
}

export enum CompensationMedium {
  ELECTRICITY = "ELECTRICITY",
  FUEL = "FUEL",
  HEAT = "HEAT",
}

export enum CompensationOrderStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  CONFIRMED = "CONFIRMED",
  CREATED = "CREATED",
  IN_VERIFICATION = "IN_VERIFICATION",
  PAID = "PAID",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  REJECTED = "REJECTED",
}

export enum CredentialFieldType {
  PASSWORD = "PASSWORD",
  TEXT = "TEXT",
}

export enum DashboardTileTextPosition {
  ABSOLUTE = "ABSOLUTE",
  RELATIVE = "RELATIVE",
}

export enum DynamicPropertyType {
  LIST = "LIST",
  MAP = "MAP",
  NONE = "NONE",
}

export enum LicenseStatus {
  SUBSCRIBED = "SUBSCRIBED",
  UNLICENSED = "UNLICENSED",
  WILL_EXPIRE = "WILL_EXPIRE",
}

export enum MeasurandFillingStrategyType {
  CONSTANT = "CONSTANT",
  DISTRIBUTED_EQUAL = "DISTRIBUTED_EQUAL",
  DISTRIBUTED_WEIGHTED = "DISTRIBUTED_WEIGHTED",
}

export enum MeasurementSourceId {
  ACCOUNTING = "ACCOUNTING",
  ACCOUNTING_AUTOMATED = "ACCOUNTING_AUTOMATED",
  API = "API",
  DEMO = "DEMO",
  KIWIGRID = "KIWIGRID",
  MANUAL = "MANUAL",
  MSCONS = "MSCONS",
  SIGFOX = "SIGFOX",
  UNKNOWN = "UNKNOWN",
}

export enum MessageLevel {
  ERROR = "ERROR",
  INFO = "INFO",
  WARNING = "WARNING",
}

export enum NotificationGroup {
  ERRORS = "ERRORS",
  MISSING_DATA = "MISSING_DATA",
  OTHERS = "OTHERS",
  REPORTS = "REPORTS",
  SUBSCRIPTIONS = "SUBSCRIPTIONS",
}

export enum PossibleReportActions {
  CAN_DELETE = "CAN_DELETE",
  CAN_DOWNLOAD_PDF = "CAN_DOWNLOAD_PDF",
  CAN_EDIT = "CAN_EDIT",
  CAN_GENERATE = "CAN_GENERATE",
  CAN_REGENERATE = "CAN_REGENERATE",
  CAN_SUBMIT = "CAN_SUBMIT",
  CAN_UNLOCK = "CAN_UNLOCK",
}

export enum PropertyOrigin {
  ACCOUNTING_FORMULA = "ACCOUNTING_FORMULA",
  CREDENTIAL = "CREDENTIAL",
  NODE_ATTRIBUTE = "NODE_ATTRIBUTE",
  NODE_PROPERTY = "NODE_PROPERTY",
}

export enum PropertyType {
  DATE = "DATE",
  FORMULA_FIELD = "FORMULA_FIELD",
  FORMULA_VARIABLES = "FORMULA_VARIABLES",
  MULTILINE_TEXT = "MULTILINE_TEXT",
  MULTI_VALUE = "MULTI_VALUE",
  NUMBER = "NUMBER",
  SINGLE_NODE_SELECTION = "SINGLE_NODE_SELECTION",
  SINGLE_VALUE = "SINGLE_VALUE",
  TAGS = "TAGS",
  TARIFF = "TARIFF",
  TEXT = "TEXT",
}

export enum ReportPeriod {
  ANNUALLY = "ANNUALLY",
  BIANNUALLY = "BIANNUALLY",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
}

export enum ReportStatus {
  GENERATED = "GENERATED",
  INFO = "INFO",
  LICENSED = "LICENSED",
  LOCKED = "LOCKED",
  WARNING = "WARNING",
}

export enum ReportVisibleElementType {
  ID_ATTRIBUTE = "ID_ATTRIBUTE",
  NAME_ATTRIBUTE = "NAME_ATTRIBUTE",
}

/**
 * The source format of the data to be used for tariff calculations
 */
export enum SourceFormat {
  MASS = "MASS",
  VOLUME = "VOLUME",
  WORK = "WORK",
}

export enum UnitAccessControl {
  ALLOW = "ALLOW",
  ALLOW_CHILD_INHERIT = "ALLOW_CHILD_INHERIT",
  ALLOW_INHERIT = "ALLOW_INHERIT",
  DENY_INHERIT = "DENY_INHERIT",
}

export enum ValueType {
  DEFAULT = "DEFAULT",
  DIRECT = "DIRECT",
  LIST = "LIST",
  MAP = "MAP",
  NULL = "NULL",
  VARIABLE = "VARIABLE",
}

export enum Visibility {
  COMPANY = "COMPANY",
  GLOBAL = "GLOBAL",
  USER = "USER",
}

export interface AssignmentUpdate {
  add?: string[] | null;
  remove?: string[] | null;
}

export interface CompensationDistributionDetailsInput {
  nodeId: string;
  start: any;
  end: any;
  weightedBy?: DataPointInput | null;
}

export interface CredentialInput {
  credentialDefinitionId: string;
  name?: string | null;
  fields: CredentialInputField[];
}

export interface CredentialInputField {
  key: string;
  value?: string | null;
}

export interface DashboardTilePositionUpdate {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface DashboardTileTextConfigurationInput {
  text?: string | null;
  color?: string | null;
  backgroundColor?: string | null;
  position?: DashboardTileTextPosition | null;
  top?: number | null;
  bottom?: number | null;
  left?: number | null;
  right?: number | null;
  fontSize?: number | null;
}

export interface DashboardTileUpdate {
  widgetId: string;
  title?: string | null;
  position: DashboardTilePositionUpdate;
  configuration?: WidgetPropertyValueInput[] | null;
  text?: DashboardTileTextConfigurationInput | null;
}

export interface DashboardUpdate {
  title: string;
  variables: DashboardVariableUpdate[];
  tiles: DashboardTileUpdate[];
  visibility: Visibility;
  columns: number;
  rowHeight: number;
}

export interface DashboardVariableUpdate {
  variableName: string;
  selectionWidgetId: string;
  configuration?: WidgetPropertyValueInput[] | null;
}

export interface DataPointInput {
  nodeId: string;
  measurand: string;
}

export interface DateRangeInput {
  start: any;
  end: any;
}

export interface FixedPriceConfigurationInput {
  price: number;
}

export interface GroupDetails {
  name: string;
}

export interface ManualMeasurementDataEntryInput {
  measurand: string;
  value: number;
  filling?: ManualMeasurementFillingInput | null;
}

export interface ManualMeasurementDataInput {
  start?: any | null;
  end: any;
  data: ManualMeasurementDataEntryInput[];
  overwriteExistingValues?: boolean | null;
}

export interface ManualMeasurementFillingInput {
  type: MeasurandFillingStrategyType;
  measurement?: ManualMeasurementFillingMeasurement | null;
}

export interface ManualMeasurementFillingMeasurement {
  measurement: string;
  measurand: string;
}

export interface MonitoringActionsInput {
  generateNotification: boolean;
  deleteNotificationOnSuccess: boolean;
  sendMailUserIds?: string[] | null;
}

export interface MonitoringJobConfiguration {
  name: string;
  monitoringRuleId: string;
  trigger: MonitoringTriggerInput;
  actions: MonitoringActionsInput;
  configuration: any;
}

export interface MonitoringTriggerInput {
  cron?: string | null;
  intervalInMinutes?: number | null;
}

export interface NodeAccessControlScopesInput {
  readProperties?: UnitAccessControl | null;
  writeProperties?: UnitAccessControl | null;
  deleteNode?: UnitAccessControl | null;
  createChild?: UnitAccessControl | null;
  writeTimeseries?: UnitAccessControl | null;
  readTimeseries?: UnitAccessControl | null;
}

export interface PropertyChange {
  key: string;
  timeScopedValues: TimeScopedPropertyValueInput[];
  origin: PropertyOrigin;
}

export interface PropertySnapshot {
  parentId: string;
  unitType: string;
  propertyValues: PropertyChange[];
}

export interface PurchaseCompensationItemRequest {
  compensationItem: string;
  quantity: number;
  currency: string;
  pricePerUnit: number;
  distribution?: CompensationDistributionDetailsInput | null;
  medium?: CompensationMedium | null;
  token: string;
}

export interface ReportPeriodDefinition {
  reportSource: string;
  reportTemplate: string;
  reportPeriod: ReportPeriod;
}

export interface ReportValue {
  field: string;
  value: string;
}

export interface SubscriptionCreation {
  group: string;
  member: string;
  dateRange: DateRangeInput;
  autoExtends?: boolean | null;
  allocatedQuota?: number | null;
}

export interface SubscriptionUpdate {
  dateRange: DateRangeInput;
  autoExtends?: boolean | null;
  allocatedQuota?: number | null;
}

export interface TariffInput {
  name: string;
  currency: string;
  sourceFormat: SourceFormat;
  end?: any | null;
  timeBoundConfiguration: TimeBoundConfigurationInput[];
}

export interface TimeBoundConfigurationInput {
  start: any;
  fixedPrice?: FixedPriceConfigurationInput | null;
}

export interface TimeScopedPropertyValueInput {
  start?: string | null;
  end?: string | null;
  value: string[];
}

export interface UnitCreation {
  type: string;
  parentId: string;
}

export interface UserRegistration {
  email: string;
  firstName: string;
  lastName: string;
}

export interface UserSettingsInput {
  csvExportDelimiter?: string | null;
  csvNumberFormatLanguage?: string | null;
}

export interface WidgetConfigValueInput {
  type: ValueType;
  variableName?: string | null;
  directValue?: any | null;
  mapValues?: WidgetPropertyMapValueInput[] | null;
  listValues?: WidgetConfigValueInput[] | null;
}

export interface WidgetConfigurationInput {
  key: string;
  value: any;
}

export interface WidgetPropertyMapValueInput {
  key: string;
  value?: WidgetConfigValueInput | null;
}

export interface WidgetPropertyValueInput {
  propertyKey: string;
  config: WidgetConfigValueInput;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
