import gql from 'graphql-tag';

export const queries = {
  getCompensationItems: gql`
    query GetCompensationItems {
      compensationItems {
        id
        name
        description
        minQuantity
        maxQuantity
        supplier {
          id
          name
        }
        validMedia
        pricePerUnit {
          value
          currency
        }
      }
    }
  `,

  unitNameAndType: gql`
    query UnitNameAndType($id: ID!) {
      unit(id: $id) {
        name
        type {
          mainType
        }
        children {
          items {
            type {
              mainType
            }
            measurands {
              id
            }
            properties {
              items {
                key
                value {
                  start
                  end
                  value
                }
              }
            }
          }
        }
      }
    }
  `,

  getCompensationOrders: gql`
    query GetCompensationOrders {
      compensationOrders {
        orderId
        created
        currency
        compensationItemName
        pricePerUnit
        medium
        supplier {
          id
          name
        }
        certificateId
        certificateLink
        orderedBy {
          firstName
          lastName
        }
        quantity
        status
        totalPrice
        distributionDetails {
          nodeId
          start
          end
        }
      }
    }
  `,

  getCompensationOrderLogs: gql`
    query GetCompensationOrderLogs($orderId: ID!) {
      compensationOrderLogs(orderId: $orderId) {
        status
        message
        time
      }
    }
  `,
};

export const mutations = {
  purchaseCompensationItem: gql`
    mutation PurchaseCompensationItem($request: PurchaseCompensationItemRequest!) {
      purchaseCompensationItem(request: $request) {
        __typename
        ... on BadRequestErrors {
          badRequest {
            errorCode
            field
            message
          }
        }
        ... on ConflictError {
          message
        }
        ... on CompensationOrder {
          created
          orderId
        }
      }
    }
  `,
};
