import { createModel } from '@rematch/core';
import { RootModel } from 'global-store/models';

export type UnitDialogState = {
  dialogState: boolean;
  id: string | null;
  parentId?: string;
  unitType?: string;
  measurementSource?: string;
};

export const unitDialog = createModel<RootModel>()({
  state: { dialogState: false, id: '' } as UnitDialogState,
  reducers: {
    handleUnitDialogState: (state, payload: UnitDialogState) => ({
      ...state,
      ...payload,
    }),
  },
});
